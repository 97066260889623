<!--
 * @Author: wangwei
 * @Date: 2020-12-29 16:05:36
 * @LastEditTime: 2021-01-08 14:11:44
 * @LastEditors: Please set LastEditors
 * @Description: 我的余额
 * @FilePath: /java-pc/src/views/member/Header.vue
-->
<template>
     <div class="sld_my_balance">
          <MemberTitle :memberTitle="L['我的余额']"></MemberTitle>

          <div v-if="!firstLoad">
               <div v-if="accountInfo.state > 0 && !showRes">
                    <div class="top_con">
                         <div class="top_info flex_row_center_center">
                              <div class="total flex_column_between_start">
                                   <span class="title">{{ L['总金额'] }}(元)：</span>
                                   <div class="red flex_row_center_center">
                                        <span class="">{{ getPartNumber(balance_info.data.totalBalance, 'int') || 0
                                        }}</span>
                                        <span class="fixed" v-if="Number(balance_info.data.totalBalance) > 0">
                                             {{ getPartNumber(balance_info.data.totalBalance, 'decimal') || '00' }}
                                        </span>
                                   </div>
                              </div>
                              <div class="total flex_column_between_start">
                                   <span class="title">{{ L['可用余额'] }}(元)：</span>
                                   <div class="red flex_row_center_center">
                                        <span class="">{{ getPartNumber(balance_info.data.balance, 'int') || 0 }}</span>
                                        <span class="fixed" v-if="Number(balance_info.data.balance) > 0">
                                             {{ getPartNumber(balance_info.data.balance, 'decimal') || '00' }}
                                        </span>
                                   </div>
                              </div>
                              <div class="btn_con flex_column_center_center">
                                   <span v-if="rechargeSetting == 1" class="recharge pointer" @click="toRecharge">{{
                                        L['充值']
                                   }}</span>
                                   <span v-if="outputEnable" class="putout pointer" @click="toPutout">提现</span>
                              </div>
                         </div>
                         <div class="times_tip" v-if="balance_info.data.remainWithdrawTime != null">
                              本月还可提现{{ balance_info.data.remainWithdrawTime }}次
                         </div>
                    </div>




                    <div class="nav_list flex_row_start_center">
                         <div :class="{ item: true, active: current_type == 'balance', pointer: true }"
                              @click="change('balance')">
                              {{ L['余额明细'] }}
                         </div>
                         <div :class="{ item: true, active: current_type == 'recharge', pointer: true }"
                              @click="change('recharge')" v-if="rechargeSetting == 1">
                              {{ L['充值明细'] }}
                         </div>
                         <div v-if="outputEnable"
                              :class="{ item: true, active: current_type == 'toPutout', pointer: true }"
                              @click="change('toPutout')">
                              提现明细
                         </div>
                    </div>
                    <!-- 余额明细列表 -->
                    <div v-if="current_type == 'balance'" class="list_container">
                         <div class="top_title flex_row_center_center">
                              <div class="time flex_row_center_center">{{ L['交易日期'] }}</div>
                              <div class="num flex_row_center_center">{{ L['收入/支出'] }}(元)</div>
                              <div class="reason">{{ L['变动原因'] }}</div>
                         </div>
                         <div class="list_item flex_row_center_center" v-for="(balanceItem, index) in balance_list.data"
                              :key="index">
                              <div class="time flex_row_center_center">{{ balanceItem.createTime }}</div>
                              <div
                                   :class="{ num: true, green: true, flex_row_center_center: true, red: balanceItem.state == 2 }">
                                   {{ balanceItem.state == 1 ? '+' : '-' }}{{ balanceItem.changeValue }}</div>
                              <div class="reason">{{ balanceItem.description }}</div>
                         </div>
                         <SldCommonEmpty tip="暂无余额明细~" totalHeight="587" totalWidth="1003"
                              v-show="!balance_list.data.length" />
                    </div>
                    <!-- 充值明细 -->
                    <div v-else-if="current_type == 'recharge'" class="list_container">
                         <div class="top_title flex_row_center_center">
                              <div class="time flex_row_center_center">{{ L['交易日期'] }}</div>
                              <div class="num flex_row_center_center">{{ L['充值金额'] }}(元)</div>
                              <div class="reason">{{ L['状态'] }}</div>
                         </div>
                         <div class="list_item flex_row_center_center"
                              v-for="(rechargeItem, index) in recharge_list.data" :key="index">
                              <div class="time flex_row_center_center">{{ rechargeItem.createTime }}</div>
                              <div class="num flex_row_center_center">{{ rechargeItem.payAmount }}</div>
                              <div class="reason">{{ rechargeItem.payStateValue }}</div>
                         </div>
                         <SldCommonEmpty tip="暂无充值明细~" totalHeight="587" totalWidth="1003"
                              v-show="!recharge_list.data.length" />
                    </div>
                    <!-- 提现明细 -->
                    <div v-else-if="outputEnable && current_type == 'toPutout'" class="list_container">
                         <div class="top_title putout flex_row_center_center">
                              <div class="sn">提现单号</div>
                              <div class="time">申请时间</div>
                              <div class="money">提现金额</div>
                              <div class="extra">手续费</div>
                              <div class="state">状态</div>
                              <div class="reason">失败原因</div>
                              <div class="operate">操作</div>
                         </div>
                         <div v-if="output_list.data.length">
                              <div v-for="(item, index) in output_list.data" :key="index"
                                   class="list_item putout flex_row_center_center">
                                   <div class="sn">{{ item.withdrawalSn }}</div>
                                   <div class="time">{{ item.createTime }}</div>
                                   <div class="money" v-if="item.amount">￥{{ item.amount.toFixed(2) }}</div>
                                   <div class="extra">￥{{ item.fee.toFixed(2) }}</div>
                                   <div class="state">{{
                                        item.state == 10 ? '提现中' : item.state == 20 ? '提现成功' : '提现失败'
                                   }}</div>
                                   <div class="reason"
                                        :title="item.failReason && item.failReason.length > 9 ? item.failReason : ''">
                                        {{ item.failReason || '--' }}
                                   </div>
                                   <div class="operate" @click="outputInfo(item.withdrawalSn)"><span>查看</span></div>
                              </div>
                         </div>

                         <SldCommonEmpty tip="暂无提现明细~" totalHeight="587" totalWidth="1003"
                              v-show="!output_list.data.length" />
                    </div>
                    <!-- 分页 start -->
                    <div class="flex_row_end_center sld_pagination">
                         <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
                              :page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
                              :hide-on-single-page="true">
                         </el-pagination>
                    </div>
                    <!-- 分页 end -->
               </div>


               <div v-else-if="showRes" class="result">
                    <AuthResult></AuthResult>
               </div>

               <div class="result" v-else>
                    <div style="width:100%;" class="res_state flex_column_center_center">
                         <img src="@/assets/auth_avatar.png" style="width:118px; height:118px" />
                         <p>尚未完成实名认证</p>
                         <span></span>
                         <div class="button_primary" @click="goAuth">立即认证</div>
                    </div>
               </div>



          </div>
     </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { useStore } from 'vuex';
import { ElMessage, ElPagination } from "element-plus";
import { getCurrentInstance, onMounted, reactive, ref, watchEffect } from "vue";
import MemberTitle from '../../../components/MemberTitle';
import SldCommonEmpty from '../../../components/SldCommonEmpty'
export default {
     name: "Balance",
     components: {
          MemberTitle,
          SldCommonEmpty,
          ElPagination
     },
     setup() {
          const router = useRouter()
          const route = useRoute()
          const { proxy } = getCurrentInstance();
          const L = proxy.$getCurLanguage();
          const store = useStore();
          const memberInfo = ref(store.state.memberInfo);
          const modalVisible = ref(false);
          const pdVisible = ref(false);
          const passwordErr = ref('');
          const password = ref('');
          const pageData = reactive({
               current: 1,
               pageSize: 20,
               total: 0
          });
          const balance_list = reactive({ data: [] })
          const recharge_list = reactive({ data: [] })
          const balance_info = reactive({ data: {} })
          const output_list = reactive({ data: [] })
          const current_type = ref('balance')//当前列表显示的数据类型
          const outputEnable = ref(true); //提现是否开启
          const accountInfo = ref({})
          const bankCards = ref([])
          const getPartNumber = proxy.$getPartNumber
          const firstLoad = ref(true)
          const showRes = ref(false)
          const getBalanceList = () => {//获取余额明细列表
               var param = {};
               param.state = 0;
               param.current = pageData.current;
               param.pageSize = pageData.pageSize;
               proxy
                    .$get("v3/member/front/balanceLog/list", param)
                    .then(res => {
                         if (res.state == 200) {
                              balance_list.data = res.data.list;
                              // balance_info.data.rechargeSum = new Number(balance_info.data.rechargeSum).toFixed(2)
                              // balance_info.data.balanceAvailable = new Number(balance_info.data.balanceAvailable).toFixed(2)
                              balance_list.data.map(item => item.changeValue = new Number(item.changeValue).toFixed(2))
                              pageData.total = res.data.pagination.total;
                         } else {
                              ElMessage(res.msg);
                         }
                    })
                    .catch(() => {
                         //异常处理
                    });
          };
          const getRechargeList = () => {//获取充值明细列表
               var param = {};
               param.current = pageData.current;
               param.pageSize = pageData.pageSize;
               proxy
                    .$get("v3/member/front/balanceRecharge/list", param)
                    .then(res => {
                         if (res.state == 200) {
                              recharge_list.data = res.data.list;
                              recharge_list.data.map(item => item.payAmount = new Number(item.payAmount).toFixed(2))
                              pageData.total = res.data.pagination.total;
                         } else {
                              ElMessage(res.msg);
                         }
                    })
                    .catch(() => {
                         //异常处理
                    });
          };

          const getOutputList = () => { //获取提现明细
               var param = {};
               param.current = pageData.current;
               param.pageSize = pageData.pageSize;
               proxy
                    .$get("v3/member/front/withdrawal/list", param)
                    .then(res => {
                         if (res.state == 200) {
                              output_list.data = res.data.list;
                              pageData.total = res.data.pagination.total;
                         } else {
                              ElMessage(res.msg);
                         }
                    })

          };
          const initList = () => {
               if (current_type.value == 'balance') {
                    getBalanceList();
               } else if (current_type.value == 'recharge') {
                    getRechargeList()
               } else {
                    getOutputList();
               }
          }
          //切换
          const change = (type) => {
               pageData.current = 1
               current_type.value = type;
               initList()
          };
          //向前翻页
          const handlePrevCilickChange = () => {
               pageData.current--;
               initList();
          };
          //向后翻页
          const handleNextCilickChange = () => {
               pageData.current++;
               initList();
          };
          //页数改变
          const handleCurrentChange = current => {
               pageData.current = current;
               initList();
          };
          //去充值
          const toRecharge = () => {
               router.push({
                    path: '/member/recharge'
               })
          };
          //提现
          const toPutout = () => {

               if (!outputEnable.value) {
                    ElMessage.warning('提现功能已关闭')
                    return
               }

               let startTime = new Date().getHours()
               if (startTime < 7 || startTime >= 20) {
                    ElMessage.warning('每日提现时间为7至20时')
                    return
               }

               if (!bankCards.value.length) {
                    proxy.$confirm('尚未绑定银行卡，请绑定本人银行卡后 再进行提现操作?', '提示', {
                         confirmButtonText: '确定',
                         cancelButtonText: '取消',
                         center: true,
                         type: 'warning'
                    }).then(() => {
                         router.push('/member/addCard')
                    })

                    return
               }


               if (accountInfo.value.state == 1) {
                    proxy.$confirm('尚未绑定银行卡，尚未完成签约，完成后可进行 提现操作。', '提示', {
                         confirmButtonText: '确定',
                         cancelButtonText: '取消',
                         center: true,
                         type: 'warning'
                    }).then(() => {
                         proxy.$get('v3/member/front/openAccount/sign', {
                              jumpPageType: 1,
                              source: 2
                         }).then(res => {
                              if (res.state == 200) {
                                   window.location.href = res.data
                                   // console.log(res.data)
                              }
                         })
                    })

                    return
               }



               if (balance_info.data.remainWithdrawTime != null && (balance_info.data.remainWithdrawTime <= 0)) {
                    ElMessage.warning('本月可提现次数已用完')
                    return
               }


               router.push('/member/balance/operate')
          };
          //提现详情
          const outputInfo = (id) => {
               router.push({
                    path: '/member/balance/info',
                    query: {
                         id
                    }
               })
          };
          //取消弹窗
          const close_dialog = () => {
               modalVisible.value = false;
          };
          //设置支付密码
          const set_dialog = () => {
               router.push({
                    path: '/member/pwd/pay',
                    query: {
                         type: 'set'
                    }
               })
          };
          const close_pd = () => {
               pdVisible.value = false;
               password.value = '';
               passwordErr.value = '';
          };
          const submit_dialog = () => {
               if (!password.value) {
                    passwordErr.value = '请输入平台支付密码';
               } else {
                    proxy
                         .$get("v3/member/front/member/cash/log/verifyPwd", {
                              payPwd: password.value
                         })
                         .then(res => {
                              if (res.state == 200) {
                                   router.push('/member/balance/operate')
                              } else {
                                   passwordErr.value = res.msg || '密码输入错误，请重新输入';
                              }
                         })

               }
          };
          const getOutputEnable = () => {
               proxy
                    .$get('v3/system/front/setting/getSettings', { names: 'tl_withdraw_member_enable,withdraw_alipay_is_enable' })
                    .then(res => {
                         if (res.state == 200) {
                              outputEnable.value = (res.data[0] == '1' && res.data[1] == '1') ? true : false;
                         }
                    })
          }


          const rechargeSetting = ref('')
          const getReChargeSetting = () => {
               proxy.$get('/v3/system/front/setting/getSettings', { names: 'recharge_is_enable' }).then(res => {
                    if (res.state == 200) {
                         rechargeSetting.value = res.data[0]
                    }
               })
          }

          //获取会员账户余额
          const getInfo = () => {
               proxy.$get('v3/member/front/withdrawal/accountInfo').then(res => {
                    if (res.state == 200) {
                         balance_info.data = res.data
                    }
               })
          }


          const varifyAccount = () => {
               proxy.$get('v3/member/front/openAccount/detail').then(res => {
                    if (res.state == 200) {
                         accountInfo.value = res.data
                         firstLoad.value = false
                    }
               })
          }

          const getBankCard = () => {
               proxy.$get('v3/member/front/bankAccount/list').then(res => {
                    if (res.state == 200) {
                         bankCards.value = res.data.list
                    }
               })
          }


          const goAuth = () => {
               router.push({
                    path: '/member/auth',
                    query: {
                         from: 'bal'
                    }
               })
          }


          watchEffect(() => {
               let { appId, bizContent, sign } = route.query
               if (appId && bizContent && sign) {
                    try {
                         let parsedBizContent = JSON.parse(decodeURIComponent(bizContent))
                         if ((parsedBizContent.method && parsedBizContent.method != "pay") || (parsedBizContent.returnValue && !parsedBizContent.returnValue.bizOrderNo)) {
                              showRes.value = true
                              history.replaceState({}, '', `${window.location.origin}/member/balance`)
                         }
                    } catch (e) {
                         console.log(e, 'callback errMsg')
                    }

               }
          })


          const next = () => {
               showRes.value = false
          }

          const AuthResult = (props, context) => {
               let signSuccess = {
                    style: 'width:340px;height:220px',
                    img: require('@/assets/empty_card.png'),
                    text: '签约成功',
                    btnBg: 'gray_btn',
                    btnTxt: '返回'
               }

               let { text, btnTxt, img, style } = signSuccess


               return (
                    <div style="width:100%;" className="res_state flex_column_center_center">
                         <img src={img} style={style} />
                         <p>{text}</p>
                         <div className="button_primary" onClick={next}>{btnTxt}</div>
                    </div>
               )
          }



          onMounted(() => {
               getBankCard()
               varifyAccount()
               getInfo()
               getBalanceList();
               getOutputEnable();
               getReChargeSetting()
          });
          return {
               L,
               firstLoad,
               pageData,
               handlePrevCilickChange,
               handleNextCilickChange,
               handleCurrentChange,
               balance_list,
               change,
               current_type,
               recharge_list,
               balance_info,
               toRecharge,
               toPutout,
               outputInfo,
               memberInfo,
               modalVisible,
               close_dialog,
               set_dialog,
               pdVisible,
               close_pd,
               passwordErr,
               submit_dialog,
               password,
               output_list,
               getOutputList,
               outputEnable,
               rechargeSetting,
               bankCards,
               accountInfo,
               balance_info,
               goAuth,
               getPartNumber,
               AuthResult,
               showRes
          };
     }
};
</script>

<style lang="scss" scoped>
@import "../../../style/balance.scss";

.sld_pagination {
     margin-top: 20px;
     margin-bottom: 20px;
}
</style>
<style lang="scss">
.times_tip {
     font-size: 14px;
     font-family: PingFang SC;
     font-weight: 500;
     color: #777777;
     margin-bottom: 10px;
     margin-top: 10px;
     padding-left: 20px;
}




.top_con {
     background-color: #fff;
     padding: 1px;
}


.sld_my_balance {
     .el-dialog {
          border-radius: 6px;
     }

     .el-dialog__title {
          color: #000000;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
     }

     .el-icon {
          font-size: 18px;
          position: relative;
          bottom: 6px;
          left: 4px;
     }

     .el-dialog__header {
          text-align: center;
          color: #000000;
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
     }

     .el-dialog__body {
          padding: 0;
     }
}


.result {
     background: #fff;
     height: 970px;

     .res_state {
          padding-top: 100px;

          p {
               margin-top: 31px;
               font-size: 16px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #333333;
          }

          span {
               margin-top: 16px;
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #999999;
               margin-bottom: 31px;
          }
     }

     .button_primary {
          width: 150px;
          height: 40px;
          background: #E73439;
          border-radius: 4px;
          text-align: center;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 40px;
          cursor: pointer;
     }
}

.result {
     background: #fff;
     height: 970px;

     .res_state {
          padding-top: 100px;

          p {
               margin-top: 31px;
               font-size: 16px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #333333;
          }

          span {
               margin-top: 16px;
               font-size: 14px;
               font-family: Microsoft YaHei;
               font-weight: 400;
               color: #999999;
               margin-bottom: 31px;
          }
     }

     .button_primary {
          width: 150px;
          height: 40px;
          background: #E73439;
          border-radius: 4px;
          text-align: center;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 40px;
          cursor: pointer;
          margin-top: 30px;
     }
}

.button_primary {
     width: 150px;
     height: 40px;
     background: #E73439;
     border-radius: 4px;
     text-align: center;
     font-size: 18px;
     font-family: Microsoft YaHei;
     font-weight: bold;
     color: #FFFFFF;
     line-height: 40px;
     cursor: pointer;
}
</style>